import config from "../constants/config";
import moment from "moment";


export function isNonEmptyArr<T>(array: T[]): boolean {
  return Array.isArray(array) && array.length !== 0;
}

export const shouldShowError = (error: string | undefined,
  touched: boolean | undefined) => {
  return error && touched
}

// export const getApiUrlEndpointAsPerRole = () => {
//   let apiEndpoint: any = {};
//   if (process.env.REACT_APP_ADMIN_APP_NAME === 'owner') {
//     apiEndpoint = config.roleBasedConfig['owner'].apiEndpoint;
//   } else {
//     apiEndpoint = config.roleBasedConfig['whitelabel'].apiEndpoint;
//   }

//   return apiEndpoint;
// }

export const formatDate = (date: Date | null) => {
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const setLocalStorageData = (data:any) =>{
  localStorage.setItem('token', data.accessToken);
  localStorage.setItem('user', data.userName);
  if(data.refereshToken) {
    localStorage.setItem('refreshToken', data.refereshToken);
    localStorage.setItem('wlType', data.wlType);
  }
}

export const removeLocalStorageData = () =>{
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('wlType');
}

export const formatIndianPayment = (number: string | null) => {
  let numStr = String(number);

  numStr = numStr.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

  return numStr;
};
