import NotificationList from "../components/notifications/NotificationList";
import NotificiationModule from "../components/notifications/NotificiationModule";

function Notification() {
    return (
        <section>
            {/* <NotificiationModule /> */}
            <NotificationList />
        </section>
    );
}
export default Notification;