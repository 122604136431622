import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';

interface ServerConfigButtonProps {
    rowId: number;
    rowData: any[];
}

export const ServerConfigButton: React.FC<ServerConfigButtonProps> = ({ rowId, rowData }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [selectedRowDetails, setSelectedRowDetails] = useState<any>(null);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        const selectedRowData = rowData.find(row => row.id === rowId);
        setSelectedRowDetails(selectedRowData);
        setTooltipOpen(true);
    };

    const handleCloseTooltip = () => {
        setTooltipOpen(false);
    };

    return (
        <Tooltip
            title={tooltipOpen && selectedRowDetails?.wlServerConfig
                ? (
                    <div>
                        <h3>Details for ID: {selectedRowDetails.wlServerConfig?.id}</h3>
                        <p>DB name: {selectedRowDetails.wlServerConfig?.dbName}</p>
                        <p>Password: {selectedRowDetails.wlServerConfig?.password}</p>
                        <p>Domains: {selectedRowDetails.wlServerConfig?.domains.map((domain: string) => <span key={domain}>{`${domain}, `}</span>)}</p>
                    </div>
                ) : <div>
                    {"No config found"}
                </div>}
            arrow
            open={tooltipOpen}
            onClose={handleCloseTooltip}
        >
            <IconButton aria-label="delete" onClick={handleButtonClick}>
                <InfoIcon color="primary" />
            </IconButton>
        </Tooltip>
    );
};