import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { images } from "../../../constants";
import SidebarContext from "../../../store/sidebarContext";
import LoginContext from "../../../store/loginContext";
import classes from "./Sidebar.module.scss";
import LogoutIcon from '@mui/icons-material/Logout';
import { ISideBarNav } from "../../../interfaces/configInterface";
import { removeLocalStorageData } from "../../../utils/utils";
import postService from "../../../services/post";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import CRMConfig from "../../../constants/config";


const logoMobile = "https://assets-bucket.b-cdn.net/magic-win-biz/cms-content/assets/images/icons/454.png";

function Sidebar() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useWindowSize();
  const location = useLocation();
  const sidebarCtx = useContext(SidebarContext);
  const loginCtx = useContext(LoginContext);

  let sidebarNav: ISideBarNav[] = CRMConfig.sidebarNav;

  function openSidebarHandler() {
    //for width>768(tablet size) if sidebar was open in width<768 was opened too.
    //just in case of tablet size and smaller then, sidebar__open can added.
    if (width <= 768) document.body.classList.toggle("sidebar__open");
  }

  function logoutHandler() {
    logOut();
    openSidebarHandler();
    loginCtx.toggleLogin();
  }

  const logOut = async () => {
    try {
      const url = CRMConfig.apiEndpoint.logoutApiUrl;
      const result = await postService(url, {});
      return result;
    } catch (error) {
      throw error;
    } finally {
      removeLocalStorageData();
      localStorage.removeItem('userbalance');
      loginCtx.toggleLogin();
    }
  }

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <div
      className={`${classes.sidebar} ${!sidebarCtx.isOpen && classes.sidebar_close
        }`}
    >
      <div className={classes.sidebar__logo}>
        {sidebarCtx.isOpen ? <img className={classes.logoDesktop} src={images.logo} alt="jontech" /> : <img className={classes.logoMobile} src={logoMobile} alt="jontech" />}
      </div>
      <div className={classes.sidebar__menu}>
        {sidebarNav.map((nav, index) => (
          <Link
            to={nav.link}
            key={`nav-${index}`}
            className={`${classes.sidebar__menu__item} ${activeIndex === index && classes.active
              }`}
            onClick={openSidebarHandler}
          >
            <div className={classes.sidebar__menu__item__icon}>
              <Tooltip title={nav.text} placement="right-start">
                <Icon component={nav.icon} />
              </Tooltip>
            </div>
            <div className={classes.sidebar__menu__item__txt}>
              {nav.text}
            </div>
          </Link>
        ))}
      </div>

      <div className={[classes.sidebar__menu, classes.logout].join("")}>
        <Link
          to="/login"
          className={classes.sidebar__menu__item}
          onClick={logoutHandler}
        >
          <div className={classes.sidebar__menu__item__icon}>
            <LogoutIcon />
          </div>
          <div className={classes.sidebar__menu__item__txt}>{"logout"}</div>
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
