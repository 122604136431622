import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { IDataTableProps } from "../../../../interfaces/interfaceCommon";
import { useState } from "react";
import { Button, InputAdornment, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import { InputBox } from "../inputBox/inputBox";
import classes from "./dataTable.module.scss";
import SearchIcon from '@mui/icons-material/Search';
import { NoRecords } from "./noRecords";
import { isNonEmptyArr } from "../../../../utils/utils";

export const DataTable: React.FunctionComponent<IDataTableProps> = ({ rowData, columns, rowsPerPageOptions, shouldShowDatePicker, shouldShowSearch, onPageChange, shouldShowActionButton, onClickActionButton, actioButtonText }) => {
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [search, setSearch] = useState("");

  const handleFromDateChange = (date: Dayjs | null) => {
    setFromDate(date);
  };

  const handleToDateChange = (date: Dayjs | null) => {
    setToDate(date);
  };

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setSearch(value);
  }

  const filteredRows = isNonEmptyArr(rowData) ? rowData.filter((row) => {
    const createdAtDate = dayjs(row.createdAt);

    // Filter by date range
    const isAfterFromDate = !fromDate || createdAtDate.isAfter(fromDate, 'day') || createdAtDate.isSame(fromDate, 'day');
    const isBeforeToDate = !toDate || createdAtDate.isBefore(toDate, 'day') || createdAtDate.isSame(toDate, 'day');

    // Filter by search query
    const lowerCaseSearchQuery = search.toLowerCase();
    const isNumericSearch = !isNaN(lowerCaseSearchQuery as any) && lowerCaseSearchQuery !== '';
    let matchesSearch = false;

    // Iterate through all properties of the row object and check if any value matches the search query
    Object.values(row).forEach((value) => {
      const stringValue = String(value).toLowerCase();
      if (typeof value === 'string' && stringValue.includes(lowerCaseSearchQuery)) {
        matchesSearch = true;
      } else if (isNumericSearch) {
        // Check if the value is numeric and matches the search query
        const numericValue = parseFloat(lowerCaseSearchQuery);
        if (!isNaN(numericValue) && numericValue === value) {
          matchesSearch = true;
        }
      }
    });
    if (shouldShowSearch && shouldShowDatePicker) {
      return isAfterFromDate && isBeforeToDate && matchesSearch;
    } else if (shouldShowSearch) {
      return matchesSearch;
    } else if (shouldShowDatePicker) {
      return isAfterFromDate && isBeforeToDate
    }
  }) : [];

  const CustomNoRowsOverlay = () => {
    return <NoRecords/>
  };

  const showActionButton = () => {
    return shouldShowActionButton
  }

  const calculateMaxWidth = () => {
    if (shouldShowDatePicker && shouldShowActionButton) {
      return "40%";
    } else if (shouldShowDatePicker || shouldShowActionButton) {
      return "60%";
    }
    return "100%";
  }

  return (
    <div style={{ width: '100%', minHeight: `${rowData?.length === 0 ? "auto" : "400px"}`, height: `${rowData?.length === 0 ? "400px" : "auto"}` }}>
      <div className={classes.topFilterWrapper}>
        {shouldShowDatePicker && <Stack display={"flex"} flexDirection={"row"} gap={2} maxWidth={"40%"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select from date"
              value={fromDate}
              onChange={handleFromDateChange}
              disabled={!rowData.length}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select to date"
              value={toDate}
              onChange={handleToDateChange}
              disabled={!rowData.length}
            />
          </LocalizationProvider>
        </Stack>}
        {shouldShowSearch && <Stack width={"100%"} maxWidth={calculateMaxWidth()}>
          <InputBox
            value={search}
            onChangeHandler={handleSearch}
            label="search records"
            variant="outlined"
            name="search"
            id="search"
            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
            disabled={!rowData?.length}
          />
        </Stack>}
        {showActionButton() && <Stack className={classes.actionButton}>
          <Button disableRipple variant="outlined" style={{ maxWidth: 200 }} onClick={onClickActionButton}>{actioButtonText}</Button>
        </Stack>}
      </div>

      <DataGrid
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
          {
            outline: 'none',
          },
        }}
        rows={filteredRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        disableAutosize
        pageSizeOptions={rowsPerPageOptions}
        checkboxSelection={false}
        getRowId={(rowData) => rowData.id}
        paginationMode="client"
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        disableRowSelectionOnClick={true}
        disableColumnMenu={true}
        onPaginationModelChange={onPageChange}
        className="dataTableMain"
      />
    </div>
  );
}
