import React, { Suspense, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import AuthLayout from "./layout/AuthLayout";
import LoadingSpinner from "./components/common/UI/loadingSpinner/LoadingSpinner";
import "./scss/App.scss";
import CRMConfig from "./constants/config";
import LoginContext from "./store/loginContext";
import CampaignReport from "./pages/CampaignReport";
import Notification from "./pages/Notification";
import Navigation from "./pages/Navigation";

const Login = React.lazy(() => import("./pages/common/Login"));
const Dashboard = React.lazy(() => import("./pages/common/Dashboard"));
const NotFound = React.lazy(() => import("./pages/common/NotFound"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path={CRMConfig.routesConstatnts.campaignReport} element={<CampaignReport />} />
              <Route path={CRMConfig.routesConstatnts.notificaton} element={<Notification />} />
              <Route path={CRMConfig.routesConstatnts.navigation} element={<Navigation />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
