import { useContext, useEffect } from "react";
import LoginContext from "../store/loginContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import axios from "axios";

const AuthLayout = () => {
  const location = useLocation();
  const loginCtx = useContext(LoginContext);
  let token:string|null = '';

  if(localStorage.getItem('refreshToken')) {
    token = localStorage.getItem('refreshToken');
  }

  // useEffect(() => {
  //   axios.post(`${process.env.REACT_APP_API_LINK + apiConstants.refreshToken}`, {
  //     refreshToken: token  
  //   }).then(response => {  
  //     if(response.data.status === 200){
  //       localStorage.setItem('refreshToken', response.data.data);
  //     }else {
  //       forceLogout();
  //     }
  //   }).catch(error => {
  //       console.error('Error:', error);
  //       forceLogout();
  //     });
  // }, []);

  function forceLogout(){
    // localStorage.removeItem('token');
    // loginCtx.toggleLogin();
  }

  return loginCtx.isLogin ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      replace
      state={{ from: location }} 
    />
  );
};

export default AuthLayout;