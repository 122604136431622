import { useFormik } from "formik";
import { Button, Stack, styled } from "@mui/material";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { InputBox } from "../common/UI/inputBox/inputBox";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUpload from "../notifications/FileUpload";
import { ICreateProps } from "../../interfaces/interfaceCommon";

export const AddNavigationBox: React.FC<ICreateProps> = ({ closeModal, modalId, refetch, id  }) => {
    
    const initialValues = {
        title: '',
        image: '',
        type: ''
    }

    // const getSelectedID = () => {
    //     let selectedIdsArr: Array<number | undefined> = [];
    //     for (let i = 0; i < values.selectModules.length; i++) {
    //         const selectedOption = moduleOptions.find(option => option.name === values.selectModules[i]);
    //         selectedIdsArr.push(selectedOption?.id);
    //     }
    //     return selectedIdsArr;
    // };

    const addDWRoleMutation = useMutation('', {
        onSuccess: () => {
            toast.success('Role created successful!');
        },
        onError: (error: any) => {
            toast.error('Role creation failed: ' + error.response?.data?.message);
        },
    });

    const { values, handleBlur, handleSubmit, handleChange, errors, touched, isValid, dirty } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            let param = {
                name: values.title,
                image: values.image,
                message: values.type,
            };
            console.log("param:::", param);
            // addDWRoleMutation.mutate(param as any);
            // action.resetForm();
        },
        // validationSchema: addDWRoleSchema
    });

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Stack marginBottom={2}>
                    <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.title} name="title" id={"title"} label={'Title'} variant="outlined" errorMessage={errors.title} isTouched={touched.title} />
                </Stack>
                <Stack marginBottom={2}>
                    <FileUpload/>
                </Stack>
                <Stack marginBottom={2}>
                    <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.type} name="type" id={"type"} label={'Type'} variant="outlined" errorMessage={errors.type} isTouched={touched.type} />
                </Stack>
                <Stack flexDirection={"row"} marginTop={1} justifyContent={"flex-end"}>
                    <Button style={{width: '150px'}}className="customBtn" disabled={!isValid || !dirty} type="submit" size="small" variant="outlined">
                        {'Send'}</Button>
                </Stack>
            </form>
        </div>
    )
}