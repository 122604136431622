import { useEffect, useState } from "react";
import axios from "axios";
import { PageHeaderSecondary } from "../common/header/PageHeaderSecondary";
import { AddMessageModule } from "./AddMessageModule";
import { ClientMessage } from "./ClientMessage";
import { FormCard } from "../common/UI/formCard/formCard";
import config from "../../constants/config";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CRMConfig from "../../constants/config";
import { ICreateProps } from "../../interfaces/interfaceCommon";
// import { IGetDWRoleState } from "../../interfaces/interfaceCommon";


// function NotificiationModule() {
export const NotificiationModule: React.FC<ICreateProps> = ({ closeModal, modalId, refetch, id  }) => {
  // const [moduleOptions, setModuleOptions] = useState<Array<{ id: number, name: string }>>([]);
  // const [rolesOptions, setRolesOptions] = useState<Array<IGetDWRoleState>>([]);
  // rolesOptions = [
  //   {
  //     id: 1,
  //     name: 'Role1',
  //     canView: true,
  //     canEdit: false,
  //     canDelete: false,
  //     canAdd: false,
  //     wlId: 1,
  //     moduleName: ['module1', 'module3' ]
  //   },
  //   {
  //     id: 3,
  //     name: 'Role2',
  //     canView: true,
  //     canEdit: false,
  //     canDelete: true,
  //     canAdd: true,
  //     wlId: 1,
  //     moduleName: ['module3', 'module4' ]
  //   }
  // ];
  // useEffect(() => {
  //   fetchModules();
  //   fetchRoles();
  // }, []);

  // const fetchModules = () => {
  //   axios.get(`${process.env.REACT_APP_API_LINK + CRMConfig.apiEndpoint.}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`
  //       }
  //     }
  //   ).then(response => {
  //     setModuleOptions(response.data.data);
  //   }).catch(error => {
  //     console.error('Error fetching modules:', error);
  //   });
  // }

  // const fetchRoles = () => {
  //   axios.get(`${process.env.REACT_APP_API_LINK + apiEndpoint.getDWRoleApiURL}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`
  //       }
  //     }
  //   ).then(response => {
  //     console.log("Resp:::",response.data.data);
  //     setRolesOptions(response.data.data);
  //   }).catch(error => {
  //     console.error('Error fetching modules:', error);
  //   });
  // }


  return (
    <>
      <div>
          <div>
            <PageHeaderSecondary title={"Unicasting"} />
            <AddMessageModule/>
            <PageHeaderSecondary title={"Broadcasting"} />
            <ClientMessage />
          </div>
        
        {/* <FormCard>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: "20%", fontWeight: "bold"}}>{"Notification"}</TableCell>
                  <TableCell align="center" style={{ width: "45%", fontWeight: "bold" }}>{"Time"}</TableCell>
                  <TableCell align="center" style={{ width: "35%", fontWeight: "bold" }}>{"Modifications"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rolesOptions.map((role, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" style={{ width: "20%" }}>{role.name}</TableCell>
                    <TableCell align="center" style={{ width: "45%" }}>{role.moduleName?.map((module, index) => {
                      return (
                        <span key={`${module}-${index}`}>{`${module}${index === role.moduleName.length - 1 ? "" : ", "}`}</span>
                      )
                    })}</TableCell>
                    <TableCell align="center" style={{ width: "35%" }}>{getPrivilages(role)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormCard> */}
      </div>
    </>
  );
}

export default NotificiationModule;