import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { SidebarContextProvider } from "./store/sidebarContext";
import { ThemeContextProvider } from "./store/themeContext";
import { LoginContextProvider } from "./store/loginContext";
import { ModalProvider } from "./store/modalContext";

// Create a client
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root')!); // Create a root
root.render(
  <QueryClientProvider client={queryClient}>
      <LoginContextProvider>
        <ThemeContextProvider>
          <SidebarContextProvider>
            <ModalProvider>
              <Toaster />
              <App />
            </ModalProvider>
          </SidebarContextProvider>
        </ThemeContextProvider>
      </LoginContextProvider>
  </QueryClientProvider>,
);
