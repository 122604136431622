import { ICRMConfig } from "../interfaces/configInterface";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const CRMConfig: ICRMConfig = {
  apiEndpoint: {
      loginApiURL: "/api/v1/auth/login",
      logoutApiUrl: "/api/v1/auth/logout"
  },
  sidebarNav: [
      {
        link: "/",
        section: "dashboard",
        icon: DashboardOutlinedIcon, 
        text: "Dashboard",
        typeToShow: [],
      },
      {
        link: "/campaignreport",
        section: "campaignreport",
        icon: CampaignIcon, 
        text: "Campaign Report",
        typeToShow: [],
      },
      {
        link: "/notificaton",
        section: "notificaton",
        icon: NotificationsActiveIcon, 
        text: "Notifications",
        typeToShow: [],
      },
      {
        link: "/navigation",
        section: "navigation",
        icon: DoubleArrowIcon, 
        text: "Navigation",
        typeToShow: [],
      },
  ],
  routesConstatnts: {
    campaignReport: "/campaignreport",
    notificaton: "/notificaton",
    navigation: "/navigation",
    // routeCustomers: "/customers",
    // routeEditCustomer: "/customers/:customerId",
  },
  pageTitles: [
    { path: '/', title: 'Dashboard' },
    { path: '/campaignreport', title: 'Campaign Report' },
    { path: '/notificaton', title: 'Notifications' },
    { path: '/navigation', title: 'Navigation' },
  ]
}
export default CRMConfig;