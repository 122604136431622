import { Button, Stack } from "@mui/material"
import { IAddModuleState } from "../../interfaces/interfaceCommon"
import { InputBox } from "../common/UI/inputBox/inputBox";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import toast from "react-hot-toast";


export const AddMessageModule: React.FC = () => {
    const initialValues = {
        name: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            let param: IAddModuleState = {
                name: values.name,
            }
            // addModuleMutation.mutate(param);
            action.resetForm();
        },
        // validationSchema: addModuleSchema
    });

    // Add module api binding
    // const addModuleMutation = useMutation(addModuleApi, {
    //     onSuccess: () => {
    //         toast.success('Module creation successful!');
    //         refetchModules();
    //     },
    //     onError: (error: any) => {
    //         toast.error('Module creation failed: ' + error.response?.data?.message);
    //     },
    // });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Stack marginBottom={2}>
                    <InputBox onChangeHandler={formik.handleChange} onBlurHandler={formik.handleBlur} value={formik.values.name} name="name" id={"name"} label={'Client Message'} variant="outlined" errorMessage={formik.errors.name} isTouched={formik.touched.name} />
                </Stack>
                <Stack marginBottom={2}>
                    <div className={'button_Wrapper'}>
                        <Button className={`customBtn`} disabled={!formik.isValid || !formik.dirty} type="submit" size="small" variant="outlined">
                            {'Send Message'}</Button>
                    </div>
                </Stack>
            </form>
        </div>
    )
}