import { IconButton } from "@mui/material"
import { IConfigButtonsProps, IPublishWhitelabelParams } from "../../interfaces/interfaceCommon";
import { useState } from "react";
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import PublishIcon from '@mui/icons-material/Publish';
import { styled, alpha } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { useModal } from "../../store/modalContext";
// import AddConfigBox from "../createConfig/AddConfigBox";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import postService from "../../services/post";
import CRMConfig from "../../constants/config";


export const ConfigsButtons: React.FC<IConfigButtonsProps> = ({ hasConfig, whitelabelId, refetchData
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { openModal, closeModal, modalId } = useModal();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const mutation = useMutation({
    //     mutationFn: async (param: IPublishWhitelabelParams) => {
    //         try {
    //             const url = `${CRMConfig.apiEndpoint.publishWhiteLabel}/${param.wlId}`;
    //             const result = await postService(url);
    //             return result;
    //         } catch (error) {
    //             throw error;
    //         }
    //     },
    //     onSuccess: (data) => {
    //         toast.success(data.data);
    //     },
    //     onError: (error: any) => {
    //         toast.error('failed: ' + error.response?.data?.message);
    //     },
    // });

    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));

    const handleAddConfig = () => {
        // const modalContent = <AddConfigBox wlId={whitelabelId} refetchData={refetchData} closeModal={closeModal} modalId={modalId} />
        // openModal(modalContent, "Add Config");
        // handleClose();
    }

    const handlePublish = () => {
        // const params: IPublishWhitelabelParams = {
        //     wlId: whitelabelId
        // }
        // mutation.mutate(params);
        handleClose();
    }


    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleAddConfig} disableRipple>
                    <AddIcon />
                    Add Config
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handlePublish} disableRipple disabled={!hasConfig}>
                    <PublishIcon />
                    Publish
                </MenuItem>
            </StyledMenu>
        </div>
    )
}