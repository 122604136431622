import { GridColDef } from "@mui/x-data-grid";
// import { products } from "../../constants/tables";
import { DataTable } from "../common/UI/table/dataTable";
import { ActionButtons } from "../common/actionButtons/ActionButtons";
import { Chip } from "@mui/material";
import { DeleteNavigation } from "./DeleteNavigation";
import { useModal } from "../../store/modalContext";
import { useState } from "react";
import { ICommonReturnType } from "../../interfaces/interfaceCommon";
import CRMConfig from "../../constants/config";
import { AddNavigationBox } from "./AddNavigationBox";
// import CreateWhiteLabelBox from "../createWhitleLabel/CreateWhiteLabel";


const NavigationList: React.FunctionComponent = () => {
    const { openModal, closeModal, modalId } = useModal();
    const [isDataLoading, setIsDataLoading] = useState(true);
    let productsTable;
    const products = [
        {
            id: 1,
            image: 'https://i0.wp.com/www.eastmojo.com/wp-content/uploads/2021/08/ICC-T20-World-Cup-2021.jpg?w=1200&ssl=1',
            title: 'Home',
            type: 'WebUrl'
        },
        {
            id: 2,
            image: 'https://image.shutterstock.com/image-photo/small-juicy-hamburger-canapes-on-260nw-570368917.jpg',
            title: 'Create',
            type: 'WebUrl'
        },
    ];

    const fetchNavigationData = async () => {
        // setIsDataLoading(true);
        // try {
        //     const url = CRMConfig.apiEndpoint;
        //     const response: ICommonReturnType = await getService(url);
        //     // setRowData(response.data);
        // } catch (error) {
        //     console.log("failed to load branding data");
        // } finally {
        //     setIsDataLoading(false);
        // }
    }

    const createColumns = (data: any[]) => {
        const columns: GridColDef[] = [
            {
                field: 'id', headerName: 'ID', resizable: false, headerAlign: "center", align: "center"
            },
            {
                field: 'image', headerName: 'Image', resizable: false, headerAlign: "center", align: "center",
                renderCell: (params) => <img src={params.value} alt="img"/>,
            },
            {
                field: 'title', headerName: 'Title', sortable: false, resizable: false, flex: 1, headerAlign: "center", align: "center"
            },
            {
                field: 'type', headerName: 'Type', sortable: false, resizable: false, flex: 1, headerAlign: "center", align: "center",
                renderCell: (params) => {
                    return(
                        <Chip label={params.value} variant="outlined" color="primary"/>
                    )
                }
            },
            {
                field: 'Actions', headerName: 'Actions', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center", renderCell: (params) => {
                    return (
                        <ActionButtons name={params.row.title}
                            deleteModal={<DeleteNavigation closeModal={closeModal} modalId={modalId} name={params.row.title} refetch={fetchNavigationData} deleteUrl={`${CRMConfig.apiEndpoint}/${params.row.id}`} />}
                            editModal={<AddNavigationBox closeModal={closeModal} modalId={modalId} refetch={fetchNavigationData} id={params.row.id} />}
                        />
                    )
                },
            },
        ];
        return columns
    }

    const handleActionButtonClick = () => {
        const modalContent = <AddNavigationBox closeModal={closeModal} modalId={modalId} refetch={fetchNavigationData} />;
        openModal(modalContent, "Add Navigation")
    }

    productsTable = (
        <DataTable
            rowData={products}
            columns={createColumns(products)}
            rowsPerPageOptions={[5, 10, 25]}
            shouldShowDatePicker={false}
            shouldShowSearch={true}
            shouldShowActionButton={true}
            actioButtonText="Add Navigation +"
            onClickActionButton={handleActionButtonClick}
        />
    );

    return (
        <>
            {productsTable}
        </>
    );
}

export default NavigationList;