import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event:any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      // Create a FormData objectxxxxxxl
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Simulate file upload
      console.log('File to upload:', selectedFile);
      // Here you can make an API call to upload the file
      // For example, using axios:
      // axios.post('/api/upload', formData)
      //      .then(response => console.log(response))
      //      .catch(error => console.error(error));
    } else {
      alert('Please select a file to upload');
    }
  };

  return (
    <div >
      <input
        accept="*"
        style={{ display: 'none' }}
        id="file-upload"
        type="file"
        onChange={handleFileChange}
      />
      
      <TextField
        style={{ width: '350px' }}
        value={selectedFile ? selectedFile.name : ''}
        placeholder="Upload Icon"
        InputProps={{
          readOnly: true,
        }}
      />
      <label htmlFor="file-upload">
        <Button variant="outlined" component="span">Choose File</Button>
      </label>
      <Button style={{ marginLeft: '10px' }} variant="outlined" color="primary" onClick={handleUpload}>Upload</Button>
    </div>
  );
};

export default FileUpload;
