import { Backdrop, IconButton, Modal } from "@mui/material"
import classes from "./muiModal.module.scss";
import CloseIcon from '@mui/icons-material/Close';
import { IMuiModalProps } from "../../../../interfaces/interfaceCommon";


export const MuiModal: React.FunctionComponent<IMuiModalProps> = ({ children, isOpen, handleModalOpenClose, title }) => {

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            onClose={handleModalOpenClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}

        >
            <div className={classes.modalContentWrapper}>
                <div className={classes.modalTitleWrapper}>
                    {title && <div>{title}</div>}
                    <div className={classes.closeButtonIcon}>
                        <IconButton aria-label="close" onClick={handleModalOpenClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>

                {children}
            </div>
        </Modal>
    )
}