import { ICommonReturnType, IWhiteLabelListComponenntProps } from "../../interfaces/interfaceCommon";
import { IWhiteLabelTable } from "../../interfaces/Itable";
import { GridColDef } from "@mui/x-data-grid";
import { ServerConfigButton } from "../common/UI/table/serverConfig";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
// import { products } from "../../constants/tables";
import { DataTable } from "../common/UI/table/dataTable";
import { ConfigsButtons } from "./configsButtons";
// import CreateWhiteLabelBox from "../createWhitleLabel/CreateWhiteLabel";
import { useModal } from "../../store/modalContext";
import { ActiveIActiveSelect } from "./activeInActiveSelect";
import { useEffect, useState } from "react";
import { ActionButtons } from "./ActionsButtons";
import { formatDate } from "../../utils/utils";
import getService from "../../services/getService";

const dropdownOptions = [
    { label: "all", value: -1 },
    { label: "digital", value: 1 },
    { label: "clothing", value: 1 },
    { label: "beauty", value: 1 },
];

const products = [
    {
        "id": 7,
        "userName": "Testingwl",
        "phoneNo": "+91 5865 985 474",
        "password": "031164126478d68c731e00f7c88518c713c94c6145af59c5c3f091fbab63d4fa2a7ae065579063ac6a8772e832004aa662adf5b45ab0fe4e99163d433c087e52",
        "ownerId": 1,
        "salt": "bc8425f1f4789498ba562335b4966079",
        "status": true,
        "hasConfig": true,
        "createdAt": "2024-07-19T14:26:17.414Z",
        "updatedAt": "2024-07-19T14:27:55.319Z",
        "wlType": "B2C",
        "accountNumber": null,
        "wlServerConfig": {
            "id": 4,
            "dbName": "Db name test",
            "dbPassword": null,
            "dbUser": null,
            "dbHost": null,
            "endPointUrl": null,
            "config": null,
            "serverIp": [
                "192.168.0.1",
                "192.168.0.1"
            ],
            "domains": [
                "testabc.com",
                "testingdca.com"
            ],
            "whiteLabelId": 7
        },
        "balance": 9500
    },
    {
        "id": 8,
        "userName": "as514",
        "phoneNo": "+91 80569 85475",
        "password": "f9791c5d672a4b8f44b6ca2e0441e8c2c0c489edc6d03fa269c18bee28e7a725057660052112819c9a641533d4db212bda40c6c715fe6b4a050c617cf2b3d90d",
        "ownerId": 1,
        "salt": "ef74f1f1ebebbda46a930cf8ec907f40",
        "status": true,
        "hasConfig": false,
        "createdAt": "2024-07-20T13:14:26.998Z",
        "updatedAt": "2024-07-20T13:14:26.998Z",
        "wlType": "B2C",
        "accountNumber": null,
        "wlServerConfig": null,
        "balance": 0
    },
    {
        "id": 9,
        "userName": "Testwl540",
        "phoneNo": "+91 85632 51241",
        "password": "17bf075c7a2ebedb5294ac728f4ab47b2b0221054e46aa937681f2ab3ed366c0da81fbecd28edec4082cde6e72091b3c242088ec616a240c21c144b0a953830f",
        "ownerId": 1,
        "salt": "0b73b45c3789253b9fd6c2d153d94431",
        "status": true,
        "hasConfig": false,
        "createdAt": "2024-07-22T13:40:18.382Z",
        "updatedAt": "2024-07-22T13:40:18.382Z",
        "wlType": "B2B",
        "accountNumber": null,
        "wlServerConfig": null,
        "balance": 0
    },
    {
        "id": 11,
        "userName": "Atess",
        "phoneNo": "+915023023698",
        "password": "d56d8ddfec3f95880a71e01c601a886096b2e70ab0bdbf1c5ae7e33d604ebb6e5412126d0f916b952f00a2de70b48328f7524deacbb3a1d5529b459b3643c27b",
        "ownerId": 1,
        "salt": "d56127678343adda2265ae1d0296b226",
        "status": true,
        "hasConfig": false,
        "createdAt": "2024-07-23T11:02:05.746Z",
        "updatedAt": "2024-07-23T11:02:05.746Z",
        "wlType": "B2C",
        "accountNumber": null,
        "wlServerConfig": null,
        "balance": 0
    },
    {
        "id": 12,
        "userName": "Teststst303",
        "phoneNo": "+918025014750",
        "password": "dcbccb4c7bd6b135ea97e6cd7e4723bd7469796239ed4dd662b8ddb1cd3f5f52304bd3b6066857743ef70224db8cd4da7104409328fc358f7299f9597c7db13e",
        "ownerId": 1,
        "salt": "82a01693a1791cbc8123cd10bce73504",
        "status": true,
        "hasConfig": false,
        "createdAt": "2024-07-23T11:04:06.755Z",
        "updatedAt": "2024-07-23T11:04:06.755Z",
        "wlType": "B2C",
        "accountNumber": null,
        "wlServerConfig": null,
        "balance": 0
    },
    {
        "id": 13,
        "userName": "adminwl",
        "phoneNo": "+97155555445777",
        "password": "8616e2a28e5bcc8ea3b2da92db276dd91a098d41bd236e2362ca92a2abaeb9b8c29eb45d8ae2c1ca81cdb75d2c4e5dab11e3ead65c3f566e0780865ffb3476b8",
        "ownerId": 1,
        "salt": "7296a91ad2c80d58944c1398a20eab91",
        "status": true,
        "hasConfig": true,
        "createdAt": "2024-07-23T11:48:03.198Z",
        "updatedAt": "2024-07-23T11:48:45.659Z",
        "wlType": "B2B",
        "accountNumber": null,
        "wlServerConfig": {
            "id": 5,
            "dbName": "p636-white-label-staging",
            "dbPassword": "root123",
            "dbUser": "postgres",
            "dbHost": "postgres",
            "endPointUrl": "postgres://postgres:root123@localhost:5432/p636-white-label-staging",
            "config": {
                "key1": "value1",
                "key2": "value2"
            },
            "serverIp": [
                "192.168.1.1",
                "192.168.1.2"
            ],
            "domains": [
                "example.com",
                "anotherexample.com"
            ],
            "whiteLabelId": 13
        },
        "balance": 500000
    },
    {
        "id": 4,
        "userName": "ashu524",
        "phoneNo": "+91 82568 59745",
        "password": "63848fcd40f70c47e7caf850828413405b784b71d81a12ce8e35a6f04d9023ad504964024f1718e943d6a1176a1de89f06dc175ae54aabb038406309e200ac9e",
        "ownerId": 2,
        "salt": "51ec0470bfff5c5632fcb7f00c31a861",
        "status": true,
        "hasConfig": true,
        "createdAt": "2024-07-19T13:24:50.165Z",
        "updatedAt": "2024-07-23T13:03:34.349Z",
        "wlType": "B2B",
        "accountNumber": null,
        "wlServerConfig": {
            "id": 3,
            "dbName": "ashish.com",
            "dbPassword": null,
            "dbUser": null,
            "dbHost": null,
            "endPointUrl": null,
            "config": null,
            "serverIp": [
                "198.168.0.1"
            ],
            "domains": [
                "ashi1.com"
            ],
            "whiteLabelId": 4
        },
        "balance": 0
    },
    {
        "id": 1,
        "userName": "ashu",
        "phoneNo": "+971 58 137 5317",
        "password": "e7192060135c977d162b87919a987708aa77bf8d782cc05cb212d4e86445ede76d5597f74ae2b064a72b642b35bf4bd0080f91e66d875cd8e5bdd1eb23732e6e",
        "ownerId": 1,
        "salt": "9904ba52c39e78c29613ed718105bcda",
        "status": true,
        "hasConfig": true,
        "createdAt": "2024-07-17T12:05:03.809Z",
        "updatedAt": "2024-07-24T09:16:35.149Z",
        "wlType": "B2B",
        "accountNumber": null,
        "wlServerConfig": {
            "id": 1,
            "dbName": "Db name",
            "dbPassword": "Admin123",
            "dbUser": "UserDb",
            "dbHost": "Db host",
            "endPointUrl": "http://www.google.com",
            "config": null,
            "serverIp": [
                "192.168.0.1"
            ],
            "domains": [
                "abc.com"
            ],
            "whiteLabelId": 1
        },
        "balance": 1111612400
    },
    {
        "id": 3,
        "userName": "Ashish",
        "phoneNo": "+91 863 547 8512",
        "password": "8e2afc30142b4309529fc894c1082ede4b7094b3613a55515b7b265fea6a89c23c89a078ed9c46cf98466bd68d21c2e6dda192cb74aff6b8b3aeeac44bc45b96",
        "ownerId": 2,
        "salt": "03ce6a439a2264ee32955756c1421164",
        "status": true,
        "hasConfig": true,
        "createdAt": "2024-07-19T13:21:57.272Z",
        "updatedAt": "2024-07-24T09:31:35.931Z",
        "wlType": "B2B",
        "accountNumber": null,
        "wlServerConfig": {
            "id": 2,
            "dbName": "DB name",
            "dbPassword": "Admin123",
            "dbUser": "DB user",
            "dbHost": "DB host",
            "endPointUrl": "http://www.google.com",
            "config": {
                "Key1": "value1"
            },
            "serverIp": [
                "192.168.0.1"
            ],
            "domains": [
                "abc.com"
            ],
            "whiteLabelId": 3
        },
        "balance": 0
    },
    {
        "id": 6,
        "userName": "Ashish532",
        "phoneNo": "+91 5868 475 214",
        "password": "9210516719d53ca72048f2055108e29935864322c0e41db20b2b208972f48bf0ff49b1f192898e0432f96e7dec2f595b7ae3a5f14fced09873cfcbb24d78b526",
        "ownerId": 2,
        "salt": "581f06f2a8568d795ef3b153fe81cb3d",
        "status": true,
        "hasConfig": true,
        "createdAt": "2024-07-19T13:32:47.272Z",
        "updatedAt": "2024-07-24T10:16:35.025Z",
        "wlType": "B2C",
        "accountNumber": null,
        "wlServerConfig": {
            "id": 6,
            "dbName": "DB name",
            "dbPassword": "Admin123",
            "dbUser": "Db user",
            "dbHost": "Db Host",
            "endPointUrl": "http://www.google.com",
            "config": {
                "Key1": "Value1",
                "Key2": "Value2"
            },
            "serverIp": [
                "192.168.0.1"
            ],
            "domains": [
                "abc.com"
            ],
            "whiteLabelId": 6
        },
        "balance": 0
    }
];

export const CampaignReportList: React.FunctionComponent<IWhiteLabelListComponenntProps> = () => {
    const [rowData, setRowData] = useState<IWhiteLabelTable[]>([]);
    const [rowDataError, setRowDataError] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const { openModal, closeModal, modalId } = useModal();
    const selected = dropdownOptions[0].value;

    const fetchData = async () => {
        setIsDataLoading(true);
        // try {
        //     const url = CRMConfig.apiEndpoint.getWhiteLabelListApiURL;
        //     const response: ICommonReturnType = await getService(url);
        //     setRowData(response.data);
        // } catch (error) {
        //     setRowDataError(error as any);
        // } finally {
        //     setIsDataLoading(false);
        // }
    };

    useEffect(() => {
        // fetchData();
    }, []);

    let productsTable;
    let tableData: IWhiteLabelTable[];

    const handleAddWhiteLabel = () => {
        // const modalContent = <CreateWhiteLabelBox refetchData={fetchData} closeModal={closeModal} modalId={modalId} />
        // openModal(modalContent, "Add Whitelabel");
    }


    const createColumns = (data: IWhiteLabelTable[]) => {
        const columns: GridColDef[] = [
            {
                field: 'id', headerName: 'ID', resizable: false, headerAlign: "center",
                align: "center"
            },
            {
                field: 'userName', headerName: 'User Name', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'wlType', headerName: 'Type', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'phoneNo', headerName: 'Phone Number', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'ownerId',
                headerName: 'User Status',
                renderCell: (params) => {
                    return (
                        <ActiveIActiveSelect status={params.row.status} id={params.row.id} />
                    )
                },
                sortable: false,
                resizable: false,
                flex: 1,
                headerAlign: "center",
                align: "center"
            },
            {
                field: 'createdAt',
                headerName: 'Created At',
                flex: 1,
                sortable: false,
                valueFormatter: (params) => formatDate(params),
                resizable: false,
                headerAlign: "center",
                align: "center"
            },
            {
                field: 'updatedAt',
                headerName: 'Updated At',
                flex: 1,
                sortable: false,
                valueFormatter: (params) => formatDate(params),
                resizable: false,
                headerAlign: "center",
                align: "center"
            },
            {
                field: 'serverConfig',
                sortable: false,
                headerName: 'Server Config',
                flex: 1,
                renderCell: (params) => {
                    return (
                        <ServerConfigButton rowId={params.row.id} rowData={data} />
                    );
                },
                resizable: false,
                headerAlign: "center",
                align: "center"
            },
            {
                field: 'config',
                sortable: false,
                headerName: 'Config',
                flex: 0.5,
                renderCell: (params) => {
                    return (
                        <ConfigsButtons hasConfig={params.row.hasConfig} whitelabelId={params.row.id} refetchData={fetchData} />
                    )
                },
                filterable: false,
                resizable: false,
                headerAlign: "center",
                align: "center"
            },
            {
                field: 'balance', headerName: 'Balance', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'actions',
                sortable: false,
                headerName: 'Actions',
                flex: 1,
                renderCell: (params) => {
                    return (
                        <></>
                        // <ActionButtons id={params.row.id} userName={params.row.userName} status={params.row.status} />
                    )
                },
                filterable: false,
                resizable: false,
                headerAlign: "center",
                align: "center"
            },
        ];
        return columns
    }


    if (isDataLoading) {
        productsTable = <LoadingSpinner />;
    } else if (rowDataError) {
        switch (selected) {
            case 1:
                tableData = products?.filter((item) => item.ownerId === selected);
                break;
            case 2:
                tableData = products?.filter((item) => item.ownerId === selected);
                break;
            case 3:
                tableData = products?.filter((item) => item.ownerId === selected);
                break;
            default:
                tableData = products;
        }

        productsTable = (
            <DataTable
                rowData={tableData}
                columns={createColumns(tableData)}
                rowsPerPageOptions={[5, 10, 25]}
                shouldShowDatePicker={true}
                shouldShowSearch={true}
                shouldShowActionButton={true}
            />
        );
    } else if (rowData && !rowDataError) {
        switch (selected) {
            case 1:
                tableData = rowData?.filter((item: IWhiteLabelTable) => item.ownerId === selected);
                break;
            case 2:
                tableData = rowData?.filter((item: IWhiteLabelTable) => item.ownerId === selected);
                break;
            case 3:
                tableData = rowData?.filter((item: IWhiteLabelTable) => item.ownerId === selected);
                break;
            default:
                tableData = rowData;
        }

        productsTable = (
            <DataTable rowData={rowData}
                columns={createColumns(tableData)}
                rowsPerPageOptions={[10, 25]}
                shouldShowDatePicker={true}
                shouldShowSearch={true}
                shouldShowActionButton={true}
                onClickActionButton={handleAddWhiteLabel}
                actioButtonText="Add Whitelabel +"
            />
        );
    }

    productsTable = (
        <DataTable
            rowData={products}
            columns={createColumns(products)}
            rowsPerPageOptions={[5, 10, 25]}
            shouldShowDatePicker={true}
            shouldShowSearch={true}
            shouldShowActionButton={true}
            onClickActionButton={handleAddWhiteLabel}
            actioButtonText="Add Campaign +"
        />
    );

    return (
        <>
            {productsTable}
        </>
    );
}