import { IconButton } from "@mui/material"
import { IActionsButtonsProps } from "../../../interfaces/interfaceCommon";
import { useModal } from "../../../store/modalContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const ActionButtons: React.FunctionComponent<IActionsButtonsProps> = ({
    name,
    deleteModal,
    editModal
}) => {
    const { openModal } = useModal();

    const handleDelete = () => {
        const modalContent = deleteModal;
        openModal(modalContent, `Delete ${name}?`);
    }

    const handleEdit = () => {
        const modalContent = editModal;
        openModal(modalContent, `Edit ${name}`);
    }

    return (
        <div className={"buttonsWrapper"}>
            <IconButton color="primary" aria-label="edit record" onClick={handleEdit}>
                <EditOutlinedIcon />
            </IconButton>
            <IconButton color="primary" aria-label="delete record" onClick={handleDelete}>
                <DeleteIcon />
            </IconButton>
        </div>
    )
}