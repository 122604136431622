import { GridColDef } from "@mui/x-data-grid";
// import { products } from "../../constants/tables";
import { DataTable } from "../common/UI/table/dataTable";
import { ActionButtons } from "../common/actionButtons/ActionButtons";
import { Chip } from "@mui/material";
import { DeleteNavigation } from "./DeleteNotification";
import { useModal } from "../../store/modalContext";
import { useState } from "react";
import { ICommonReturnType } from "../../interfaces/interfaceCommon";
import CRMConfig from "../../constants/config";
import NotificiationModule from "./NotificiationModule";
// import CreateWhiteLabelBox from "../createWhitleLabel/CreateWhiteLabel";


const NotificationList: React.FunctionComponent = () => {
    const { openModal, closeModal, modalId } = useModal();
    const [isDataLoading, setIsDataLoading] = useState(true);
    let productsTable;
    const products = [
        {
            id: 1,
            image: 'https://i0.wp.com/www.eastmojo.com/wp-content/uploads/2021/08/ICC-T20-World-Cup-2021.jpg?w=1200&ssl=1',
            title: 'Asia Cup',
            url: 'magicwin.biz',
            message: 'get ready to watch Asia cup matches from today eveing 7:30PM'
        },
        {
            id: 2,
            image: 'https://i0.wp.com/www.eastmojo.com/wp-content/uploads/2021/08/ICC-T20-World-Cup-2021.jpg?w=1200&ssl=1',
            title: 'T20 World Cup',
            url: 'magicwin.biz',
            message: 'get ready to watch Asia cup matches from today eveing 7:30PM'
        },
    ];

    const fetchNotificationData = async () => {
        // setIsDataLoading(true);
        // try {
        //     const url = CRMConfig.apiEndpoint;
        //     const response: ICommonReturnType = await getService(url);
        //     // setRowData(response.data);
        // } catch (error) {
        //     console.log("failed to load branding data");
        // } finally {
        //     setIsDataLoading(false);
        // }
    }

    const createColumns = (data: any[]) => {
        const columns: GridColDef[] = [
            {
                field: 'id', headerName: 'ID', resizable: false, headerAlign: "center", align: "center"
            },
            {
                field: 'title', headerName: 'Title', sortable: false, resizable: false, flex: 0.5, headerAlign: "center", align: "center"
            },
            {
                field: 'image', headerName: 'Image', resizable: false, headerAlign: "center", align: "center",
                renderCell: (params) => <img src={params.value} alt="img"/>,
            },
            {
                field: 'message', headerName: 'Message', sortable: false, resizable: false, flex: 1.5, headerAlign: "center", align: "center"
            },
            {
                field: 'url', headerName: 'Url', sortable: false, resizable: false, flex: 0.75, headerAlign: "center", align: "center"
            },
            {
                field: 'Actions', headerName: 'Actions', sortable: false, resizable: false, headerAlign: "center",
                align: "center", renderCell: (params) => {
                    return (
                        <ActionButtons name={params.row.title}
                            deleteModal={<DeleteNavigation closeModal={closeModal} modalId={modalId} name={params.row.title} refetch={fetchNotificationData} deleteUrl={`${CRMConfig.apiEndpoint}/${params.row.id}`} />}
                            editModal={<NotificiationModule closeModal={closeModal} modalId={modalId} refetch={fetchNotificationData} id={params.row.id} />}
                        />
                    )
                },
            },
        ];
        return columns
    }

    const handleActionButtonClick = () => {
        const modalContent = <NotificiationModule closeModal={closeModal} modalId={modalId} refetch={fetchNotificationData} />;
        openModal(modalContent, "Add Notification")
    }

    productsTable = (
        <DataTable
            rowData={products}
            columns={createColumns(products)}
            rowsPerPageOptions={[5, 10, 25]}
            shouldShowDatePicker={false}
            shouldShowSearch={true}
            shouldShowActionButton={true}
            actioButtonText="Add Notification +"
            onClickActionButton={handleActionButtonClick}
        />
    );

    return (
        <>
            {productsTable}
        </>
    );
}

export default NotificationList;