import { ChangeEvent, useState } from "react";
import SelectComponent from "../common/UI/select/selectComponent";
import { IActiveIActiveSelect, IWhitelabelStatusParams } from "../../interfaces/interfaceCommon";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import patchService from "../../services/patchService";
import CRMConfig from "../../constants/config";

export const ActiveIActiveSelect: React.FunctionComponent<IActiveIActiveSelect> = ({ status, id }) => {
    const userStatus = status ? 1 : 0;
    const [selectedUserStatus, setSelectedUserStatus] = useState(userStatus);
    // const { mutate } = useMutation({
    //     mutationFn: async (param: IWhitelabelStatusParams) => {
    //         try {
    //             const data = {
    //                 status: param.status
    //             }
    //             const url = `${CRMConfig.apiEndpoint.setWhitelabelStatus}/${param.wlId}`
    //             const result = await patchService(url, data);
    //             return result;
    //         } catch (error) {
    //             throw error;
    //         }
    //     },
    //     onSuccess: () => {
    //         toast.success("User Status has changed Successfully!");
    //     },
    //     onError: () => {
    //         toast.error("failed!");
    //     }
    // });

    const handleSelect = (e: ChangeEvent<{ value: unknown }>) => {
        setSelectedUserStatus(e.target.value as number);
        refetchWhitelabelList(e.target.value as number);
    }

    const refetchWhitelabelList = async (selectedId: number) => {
        const options = {
            status: !!selectedId,
            wlId: id
        }
        try {
            // await mutate(options);
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div style={{ marginTop: 5 }}>
            <SelectComponent
                value={selectedUserStatus}
                options={
                    [{ id: 1, name: "Active" },
                    { id: 0, name: "Suspend" }]
                }
                size="small"
                onChange={handleSelect}
                id="select-active-inactive"
                name="select-active-inactive"
            />
        </div>
    )
}